.headerStyle {
  text-align: center;
  width: 100%;
  height: 64;
  padding-inline: 0px;
}

.contentBlock {
  height: calc(100vh - (64px));
  border-right: 1px solid;
  position: relative;
  padding: 10px;
}

.textName {
  font-size: 20px;
  font-family: 'Squad Semi Bold', Helvetica, sans-serif;
  font-weight: 600;
}

.backBtn {
  position: absolute;
  left: 100px;
  top: 30px;
}

.centerContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  break-after: always;
  overflow: auto;
  height: 80vh;
}

.columnBlock {
  width: 340px;
  margin-right: 10px;
  height: 100%;
}
