.btnPos{
    position: absolute;
    right: 10px;
    bottom: 0px;
}

.scrollFor{
    height: calc(100% - 50px);
    overflow-y: scroll;
    padding-right: 10px;
    scrollbar-width: none
}

.ant-card-actions{
    height: '20px' !important;
    align-items: center;
  }

  .ant-modal, .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
   }
   .ant-modal-body {
    height: calc(100vh - 110px);
   }