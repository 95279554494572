.headerStyle {
  text-align: center;
  width: 100%;
  height: 64px;
  padding-inline: 0px;
}

.contentBlock {
  /* height: calc(100% + (64px)); */
}

.centerContent{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 85%;
  margin: auto;
  justify-content: center;
  break-after: always;
  padding-top: 100px;
  gap: 20px;
}

.footerStyle{
  display: flex;
  justify-content: right;
  padding-inline: 40px;
}

@media only screen and (min-width: 2000px) {
}

@media only screen and (max-width: 600px) {
  .textAlignCenter {
    width: 100%;
  }

  .marginPlayButton {
    margin-top: 0px;
  }
}
