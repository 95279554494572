/* @import "https://storage.cloudkwekerijbloemendaal.com/StyleSheets/platform-potlijn.css"; */

.headerStyle {
  text-align: center;
  width: 100%;
  height: 64px;
  padding-inline: 0px;
}

.contentBlock {
  height: calc(100vh - (64px));
  width: 100%;
  margin: auto;
}

.textName {
  font-size: 20px;
  font-family: 'Squad Semi Bold', Helvetica, sans-serif;
  font-weight: 600;
}

.backBtn {
  position: absolute;
  left: 100px;
  top: 30px;
}

.centerContent {
  margin: auto;
  width: 100%;
  height: 100%;
}

.subSystems {
  position: absolute;
  top: 0;
  left: 20px;
  height: 200px;

  bottom: 0;
  margin: auto 0;
  width: 50px;
  border-radius: 10px;
}

.diagonalLine {
  border-bottom: 1px solid rgb(0, 0, 0);
  width: 20px;
  transform: rotate(45deg);
  transform-origin: left;
}
