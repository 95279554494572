.headerStyle {
  text-align: center;
  width: 100%;
  height: 64px;
  padding-inline: 0px;
}

.contentBlock {
  height: calc(100vh - (64px));
  width: 100%;
  display: flex;
}

.contentText {
  padding-top: 20px;
  width: fit-content;
  text-align: start;
  font-size: large;
}

.middleBlock {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 2000px) {
  .collapseBlock {
    margin-top: 20px;
  }
}
