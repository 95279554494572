.btnPos{
    position: absolute;
    right: 10px;
    bottom: 0px;
}

.scrollFor{
    height: calc(100% - 50px);
    overflow-y: scroll;
    padding-right: 10px;
    scrollbar-width: none
}

.ant-card-actions{
    height: '20px' !important;
    align-items: center;
  }

  ::-webkit-scrollbar-corner { background: rgba(180, 6, 6, 0.5); }

  #style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(176, 27, 27, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}