.headerStyle {
  text-align: center;
  width: 100%;
  height: 64px;
  padding-inline: 0px;
}

.contentBlock {
  padding-top: 30px;
  height: calc(100vh - (64px));
}

.middleBlock {
  width: 80%;
  margin: auto;
}
