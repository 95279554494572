:root {
  --main-scale-factor: 2;
}

.green-transport-band{
  position: absolute;
  width: 4%;
  height: 25%;
  margin-top: 20%;
  margin-left: 3%;
  background-color: #c1d101;
  border-radius: 5px;
}

.green-transport-band-tray-detecter{
  position: absolute;
  width: 1%;
  height: 1%;
  margin-top: 20%;
  margin-left: 7%;
  background-color: orange;
  border-radius: 5px;
}

 .green-transport-band-tray-detecter-action{
  background-color: red;
}

.tray-destacker{
  position: absolute;
  width: 4%;
  height: 16%;
  margin-left: 3%;
  margin-top: 8%;
  background-color: gray;
  border-radius: 5px;
}

.tray-destacker-left-panel{
  position: absolute;
  width: 0.25%;
  height: 5%;
  margin-left: 2.7%;
  margin-top: 8%;
  background-color: red;
  border-radius: 5px;
}

.tray-destacker-right-panel{
  position: absolute;
  width: 0.25%;
  height: 5%;
  margin-left: 7.1%;
  margin-top: 8%;
  background-color: red;
  border-radius: 5px;
}

.tray-destacker-emergency{
  position: absolute;
  width: 4.5%;
  height: 13%;
  margin-left: 2.2%;
  margin-top: 7.5%;
  border: 2px solid;
  border-radius: 5px;
  border-color: #c1d101;
  /* background-color: red; */
}

.tray-destacker-roller-band-begin{
  position: absolute;
  width: 3%;
  height: 4%;
  margin-left: 3%;
  margin-top: 4.9%;
  background-color: #c1d101;
  border-radius: 0px 0px 5px 5px;
}

.tray-destacker-roller-band-middle{
  position: absolute;
  width: 3%;
  height: 4%;
  margin-left: 3%;
  margin-top: 3%;
  background-color: #c1d101;
  border-radius: 50px 5px 5px 5px;
}

.tray-destacker-roller-band-end{
  position: absolute;
  width: 2%;
  height: 4%;
  margin-left: 5.9%;
  margin-top: 3%;
  background-color: #c1d101;
  border-radius: 0px 5px 5px 0px;
}

.javo-green-band-begin{
  position: absolute;
  width: 2.5%;
  height: 4%;
  margin-left: 8.5%;
  margin-top: 3%;
  background-color: #c1d101;
  border-radius: 5px;
}

.javo-green-band-begin-sensor{
  position: absolute;
  width: 0.5%;
  height: 1%;
  margin-left: 9%;
  margin-top: 5%;
  background-color: red;
  border-radius: 5px;
}

.javo-unit{
  position: absolute;
  width: 8%;
  height: 10%;
  margin-left: 11.5%;
  margin-top: 2.5%;
  background-color: gray;
  border-radius: 5px;
}

.javo-unit-side{
  position: absolute;
  width: 4%;
  height: 8%;
  margin-left: 13.5%;
  margin-top: 5%;
  background-color: gray;
  border-radius: 5px;
}

.javo-green-band-end{
  position: absolute;
  width: 2.5%;
  height: 4%;
  margin-left: 20%;
  margin-top: 3%;
  background-color: #c1d101;
  border-radius: 5px;
}

