:host {
  --main-factor-height: 1.1;
  --main-factor-width: 1;
  --main-factor-top: 1;
  --main-factor-left: 1;
}

.bufferband-base {
  position: absolute;
  width: 6%;
  height: 29%;
  margin-top: 19%;
  margin-left: 80%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.bufferband-long {
  position: absolute;
  width: 8%;
  height: 8%;
  margin-top: 34.5%;
  margin-left: 78%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.bufferband-short {
  position: absolute;
  width: 6%;
  height: 8%;
  margin-top: 34.5%;
  margin-left: 71.5%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.bufferband-panel-1 {
  position: absolute;
  width: 1%;
  height: 5%;
  margin-top: 38%;
  margin-left: 80%;
  background-color: #6f6971;
  border-radius: 5px;
}

.bufferband-panel-2 {
  position: absolute;
  width: 1%;
  height: 5%;
  margin-top: 38%;
  margin-left: 84%;
  background-color: #6f6971;
  border-radius: 5px;
}

.bufferband-panel-long {
  position: absolute;
  width: 7%;
  height: 2%;
  margin-top: 37%;
  margin-left: 79%;
  background-color: #6f6971;
  border-radius: 5px;
}

.bufferband-wifi {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 36%;
  margin-left: 87%;
  border-radius: 5px;
}

.bufferband-sn-combi {
  position: absolute;
  width: 0.4%;
  height: 28.6%;
  margin-top: 19%;
  margin-left: 86.2%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-sn-combi-ac {
  background-color: red;
}

.bufferband-sn-long-back {
  position: absolute;
  width: 0.4%;
  height: 28.6%;
  margin-top: 19%;
  margin-left: 79.3%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-sn-long-back-ac {
  background-color: red;
}

.bufferband-sn-top {
  position: absolute;
  width: 6%;
  height: 0.8%;
  margin-top: 18.3%;
  margin-left: 80%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-sn-top-ac {
  background-color: red;
}

.bufferband-sn-middle {
  position: absolute;
  width: 6%;
  height: 0.8%;
  margin-top: 33.75%;
  margin-left: 80%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-sn-middle-ac {
  background-color: red;
}

.bufferband-sn-noodstop {
  position: absolute;
  width: 0.5%;
  height: 0.8%;
  margin-top: 37.75%;
  margin-left: 82%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-sn-noodstop-ac {
  background-color: red;
}

.bufferband-short-1-sn {
  position: absolute;
  width: 0.4%;
  height: 8%;
  margin-top: 34.5%;
  margin-left: 72%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-short-1-sn-ac {
  background-color: red;
}

.bufferband-short-2-sn {
  position: absolute;
  width: 0.4%;
  height: 8%;
  margin-top: 34.5%;
  margin-left: 75.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-short-2-sn-ac {
  background-color: red;
}

.bufferband-short-3-sn {
  position: absolute;
  width: 0.4%;
  height: 8%;
  margin-top: 34.5%;
  margin-left: 76.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.bufferband-short-3-sn-ac {
  background-color: red;
}

.snarenband-1 {
  position: absolute;
  width: 7%;
  height: 2%;
  margin-top: 34.5%;
  margin-left: 64%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.snarenband-2 {
  position: absolute;
  width: 7%;
  height: 2%;
  margin-top: 36%;
  margin-left: 64%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.snarenband-3 {
  position: absolute;
  width: 7%;
  height: 2%;
  margin-top: 37.4%;
  margin-left: 64%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.transportband-geel {
  position: absolute;
  width: 30%;
  height: 8%;
  margin-top: 34.5%;
  margin-left: 33%;
  background-color: #f5c20f;
  border-radius: 5px;
}

.tta-base {
  position: absolute;
  width: 6%;
  height: 23%;
  margin-top: 22.5%;
  margin-left: 27%;
  background-color: #096395;
  border-radius: 5px;
}

.trayvuller1-base {
  position: absolute;
  width: 15%;
  height: 10%;
  margin-top: 10.5%;
  margin-left: 38%;
  background-color: #00592d;
  border-radius: 5px;
}

.trayvuller1-wifi {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 12.5%;
  margin-left: 54%;
  /* background-color: #00592D; */
}

.trayvuller1-tray {
  position: absolute;
  width: 0.4%;
  height: 4%;
  margin-top: 13.6%;
  margin-left: 47.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller1-tray-ac {
  background-color: red;
}

.trayvuller1-trays {
  position: absolute;
  width: 0.4%;
  height: 12%;
  margin-top: 10%;
  margin-left: 50.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller1-trays-ac {
  background-color: red;
}

.trayvuller1-uit {
  position: absolute;
  width: 0.4%;
  height: 2%;
  margin-top: 14%;
  margin-left: 40.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller1-uit-ac {
  background-color: red;
}

.trayvuller1-aanuitsig {
  position: absolute;
  width: 0.4%;
  height: 2%;
  margin-top: 14%;
  margin-left: 42.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller1-aanuitsig-ac {
  background-color: red;
}

.trayvuller2-base {
  position: absolute;
  width: 15%;
  height: 10%;
  margin-top: 17.5%;
  margin-left: 38%;
  background-color: #00592d;
  border-radius: 5px;
}

.trayvuller2-wifi {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 19.5%;
  margin-left: 54%;
  /* background-color: #00592D; */
  border-radius: 5px;
}

.trayvuller2-trays {
  position: absolute;
  width: 0.4%;
  height: 12%;
  margin-top: 17%;
  margin-left: 50.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller2-trays-ac {
  background-color: red;
}

.trayvuller2-tray {
  position: absolute;
  width: 0.4%;
  height: 4%;
  margin-top: 20.7%;
  margin-left: 47.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller2-tray-ac {
  background-color: red;
}

.trayvuller2-uit {
  position: absolute;
  width: 0.4%;
  height: 2%;
  margin-top: 21%;
  margin-left: 40.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller2-uit-ac {
  background-color: red;
}

.trayvuller2-aanuitsig {
  position: absolute;
  width: 0.4%;
  height: 2%;
  margin-top: 21%;
  margin-left: 42.5%;
  background-color: greenyellow;
  border-radius: 5px;
}

.trayvuller2-aanuitsig-ac {
  background-color: red;
}

.aanvoerband-1 {
  position: absolute;
  width: 5%;
  height: 23.3%;
  margin-top: 10.5%;
  margin-left: 27.5%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.aanvoerband-2 {
  position: absolute;
  width: 4.5%;
  height: 8%;
  margin-top: 11%;
  margin-left: 33%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.aanvoerband-3 {
  position: absolute;
  width: 4.5%;
  height: 8%;
  margin-top: 18%;
  margin-left: 33%;
  background-color: #1c1f20;
  border-radius: 5px;
}

.grondverdeler-wifi {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 6.5%;
  margin-left: 36.5%;
}

.verdeler-1 {
  position: absolute;
  width: 14%;
  height: 8%;
  margin-top: -3%;
  margin-left: 30%;
  background-color: #00592d;
  border-radius: 5px;
}

.verdeler-2 {
  position: absolute;
  width: 14%;
  height: 8%;
  margin-top: 2%;
  margin-left: 30%;
  background-color: #00592d;
  border-radius: 5px;
}

.grond-vuller-sn-1 {
  position: absolute;
  width: 5px;
  height: 9%;
  margin-top: -3.2%;
  margin-left: 39%;
  background-color: greenyellow;
  border-radius: 5px;
}

.grond-vuller-sn-1-ac {
  background-color: red;
}

.grond-vuller-sn-2 {
  position: absolute;
  width: 5px;
  height: 9%;
  margin-top: 1.8%;
  margin-left: 39%;
  background-color: greenyellow;
  border-radius: 5px;
}

.grond-vuller-sn-2-ac {
  background-color: red;
}

.grondverdeler-noodstop {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-top: 5%;
  margin-left: 36%;
  background-color: greenyellow;
  border-radius: 5px;
}

.grondverdeler-noodstop-ac {
  background-color: red;
}

.grondverdeler-1-modes {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-top: -2%;
  margin-left: 31%;
  background-color: greenyellow;
  border-radius: 5px;
}

.grondverdeler-1-modes-ac {
  background-color: red;
}

.grondverdeler-2-modes {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-top: 5%;
  margin-left: 31%;
  background-color: greenyellow;
  border-radius: 5px;
}

.grondverdeler-2-modes-ac {
  background-color: red;
}
