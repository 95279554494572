.btnPos{
    position: absolute;
    right: 10px;
    bottom: 0px;
}

.scrollFor {
    height: calc(100% - 50px);
    overflow-y: scroll;
    padding-right: 10px;
    scrollbar-width: none;
  }
  